import { create } from "zustand";
import { Receipt } from "./types";

interface ReceiptState {
  receipt: Receipt | null;
  setReceipt: (data: Receipt) => void;
}

const ReceiptStore = create<ReceiptState>()((set) => ({
  receipt: null,
  setReceipt: (data) => set(() => ({ receipt: data })),
}));

export function getReceipt() {
  return ReceiptStore.getState().receipt;
}

export function setReceipt(data: Receipt) {
  ReceiptStore.getState().setReceipt(data);
}
