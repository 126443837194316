import { queryOptions } from "@tanstack/react-query";
import {
  CheckAppVersionUseCaseInput,
  VersionStorageKey,
} from "../../../domain";
import { checkAppVersionUseCase } from "../../../main/factories/use-cases/check-app-version-usecase-factory";

export const appVersionQueryFactory = {
  default: ["app-version"] as const,
  check: (
    params: CheckAppVersionUseCaseInput & {
      route: string;
      storageKey: VersionStorageKey;
    },
  ) =>
    queryOptions({
      queryKey: [
        ...appVersionQueryFactory.default,
        "check",
        params.route,
        params.storageKey,
      ],
      queryFn: () => {
        return checkAppVersionUseCase(params.storageKey).execute({
          url: params.url,
        });
      },
    }),
};
