import { useCallback } from "react";

export type SendNativeEventPayload<T extends Record<string, unknown>> = (
  type: string,
  payload: T,
) => void;

export type UseSendNativeEvent<T extends Record<string, unknown>> = {
  isNative: boolean;
  sendMessage: SendNativeEventPayload<T>;
};

export function useSendNativeEvent<
  T extends Record<string, unknown>,
>(): UseSendNativeEvent<T> {
  const isNative = typeof window !== "undefined" && !!window.ReactNativeWebView;

  const sendMessage = useCallback(
    (type: string, payload: T) => {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type, data: payload }),
        );
      }
    },
    [isNative],
  );
  return {
    isNative,
    sendMessage,
  };
}
