import { NativeEventSenderImpl } from "../../../infra/services/native-event-sender";
import type { NativeEventSender } from "../../../infra/services/native-event-sender";

class NativeEventSenderFactory {
  private static instance: NativeEventSender<any>;

  public static getInstance<
    T extends Record<string, unknown>,
  >(): NativeEventSender<T> {
    if (!NativeEventSenderFactory.instance) {
      NativeEventSenderFactory.instance = new NativeEventSenderImpl<T>();
    }
    return NativeEventSenderFactory.instance;
  }
}

export const nativeEventSender = NativeEventSenderFactory.getInstance();
