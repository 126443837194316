import { communicationManager } from "../../../../infra/services/app-communication";

export const openExternalLink = async (link: string) => {
  if (!communicationManager.isNativeApp())
    return window.open(link, "_blank", "noopener,noreferrer");

  await communicationManager.sendMessage({
    origin: "banking-external-url",
    url: link,
  });
};
