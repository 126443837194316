import type {
  BankAccount,
  FavoredContact,
  PixAccount,
} from "../../domain/entities/favored-contact";
import type {
  ContactAccountResponse,
  ContactResponse,
} from "../../domain/use-cases/contacts";

export class FavoredContactMapper {
  static toDomain(contacts: ContactResponse["Contacts"]): FavoredContact[] {
    return contacts.map((contact) => ({
      id: contact.Id,
      name: contact.ContactName,
      document: contact.Document,
      isFavored: contact.IsFavoredContact,
      accounts: contact.ContactAccounts.map(this.mapAccount),
    }));
  }

  private static mapAccount(
    account: ContactAccountResponse,
  ): PixAccount | BankAccount {
    const baseAccount = {
      id: account.Id,
      ispb: String(account.ISPB).padStart(8, "0"),
      branch: account.Branch,
      accountNumber: account.AccountNumber,
    };

    if (account.TransferType === "pix") {
      return {
        ...baseAccount,
        transferType: "pix" as const,
        pixKey: account.PixKey!,
      };
    }

    return {
      ...baseAccount,
      transferType: account.TransferType as "p2p" | "ted",
      branchDigit: account.BranchDigit!,
      accountDigit: account.AccountDigit!,
      bankCode: account.BankCode!,
      bankName: account.BankName!,
      BankAccountType: account.BankAccountType!,
      p2pAccountId: account.P2PAccountId!,
    };
  }
}
