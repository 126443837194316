import { CONTACTS_API_URL } from "../../../../config";
import {
  ListContactsUseCase,
  ListContactsUseCaseRequest,
} from "../../../../domain/use-cases/contacts/list-contacts-usecase";
import { ListContactsUseCaseImpl } from "../../../../infra/use-cases/contacts";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http/api-url-factory";

const makeListContactsUseCaseFactory = (): ListContactsUseCase => {
  return new ListContactsUseCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "Contact",
      baseUrl: CONTACTS_API_URL,
    }),
  );
};

const listContactsUseCase = makeListContactsUseCaseFactory();

export async function listContacts(params?: ListContactsUseCaseRequest) {
  const response = await listContactsUseCase.execute(params);
  if (!response.success && "error" in response) {
    throw new Error(response.error.errorMessages.join(", "));
  }

  return response.data;
}
