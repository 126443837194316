const ACCOUNT_MENU_ID = "account-menu";

export class AccountMenuChannel extends EventTarget {
  constructor() {
    super();
  }

  onMessage(callback: (isOpen: boolean) => void) {
    this.addEventListener(ACCOUNT_MENU_ID, (event) => {
      const customEvent = event as CustomEvent<{ isOpen: boolean }>;
      callback(customEvent.detail.isOpen);
    });
  }

  toggle(isOpen: boolean) {
    this.dispatchEvent(
      new CustomEvent(ACCOUNT_MENU_ID, { detail: { isOpen } }),
    );
  }

  destroy() {
    this.removeEventListener(ACCOUNT_MENU_ID, () => {});
  }
}

export const accountMenuChannel = new AccountMenuChannel();
