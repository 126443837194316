import { useCallback } from "react";
import html2canvas from "html2canvas";
import JSPdf from "jspdf";
import { useSendNativeEvent } from "../use-send-native-event";

const applyOverflowFix = (el: HTMLElement) => {
  const original = el.style.overflow;
  el.style.overflow = "visible";
  return () => {
    el.style.overflow = original;
  };
};

export const useSendPDFtoMobile = () => {
  const { isNative, sendMessage } = useSendNativeEvent();
  const generateAndSendPDF = useCallback(
    async (element: HTMLElement, filename = "comprovante.jpeg") => {
      if (!element) return;
      const restoreOverflow = applyOverflowFix(element);

      try {
        const scale = Math.min(3, window.devicePixelRatio || 1);

        const rect = element.getBoundingClientRect();
        const scrollY = -rect.top;
        const canvas = await html2canvas(element, {
          scrollY,
          useCORS: true,
          scale,
          backgroundColor: "#fff",
          foreignObjectRendering: true,
        });

        const imgData = canvas.toDataURL("image/png");
        const pdf = new JSPdf("p", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const ratio = Math.min(
          pageWidth / canvas.width,
          pageHeight / canvas.height,
        );

        const imgWidth = canvas.width * ratio;
        const imgHeight = canvas.height * ratio;

        pdf.addImage(
          imgData,
          "PNG",
          (pageWidth - imgWidth) / 2,
          (pageHeight - imgHeight) / 2,
          imgWidth,
          imgHeight,
        );
        pdf.output("datauristring");

        if (isNative) {
          sendMessage("PDF_GENERATED", {
            base64: imgData,
            filename,
          });
        }
      } catch (error) {
        console.error("Error generating PDF:", {
          error,
          elementDimensions: {
            scrollWidth: element.scrollWidth,
            scrollHeight: element.scrollHeight,
            offsetWidth: element.offsetWidth,
            offsetHeight: element.offsetHeight,
          },
        });
        throw error;
      } finally {
        restoreOverflow();
      }
    },
    [isNative, sendMessage],
  );

  return { generateAndSendPDF };
};
