import { queryOptions } from "@tanstack/react-query";
import { ListContactsUseCaseRequest } from "../../../domain/use-cases/contacts/list-contacts-usecase";
import { listContacts } from "../../../main/factories/use-cases/contacts/make-list-contacts-usecase-factory";

const ONE_MINUTE = 1000 * 60;

export const contactQueryFactory = {
  default: ["contact"] as const,
  list: (params?: ListContactsUseCaseRequest) =>
    queryOptions({
      queryKey: [
        ...contactQueryFactory.default,
        "list",
        params?.accountId,
        params?.document,
        params?.favoredContacts,
      ],
      queryFn: () => listContacts(params),
      staleTime: ONE_MINUTE,
    }),
  listFavored: (params?: ListContactsUseCaseRequest) =>
    queryOptions({
      queryKey: [...contactQueryFactory.list(params).queryKey, "favored"],
      queryFn: () => listContacts(params),
      staleTime: ONE_MINUTE,
    }),
};
