export type TransactionFee =
  | "TRANSACTION_FEE" // Fee charged for a transaction.
  | "SERVICE_FEE" // Fee charged for a service
  | "MAINTENANCE_FEE" // Fee charged for maintenance.
  | "PROCESSING_FEE" // Fee charged for processing.
  | "TAX" // Fee charged as tax.
  | "BANK_FEE" // // Fee charged as tariff.
  | "TRANSACTION_FEE_REVERSAL "; // Grupo 30: Cancelamento.

export type TransactionStatus =
  | "CREATED" // Transação registrada no sistema, mas ainda não iniciou o processamento.
  | "WAITING_FOR_APPROVAL" // Transação aguarda aprovação de um gestor ou responsável.
  | "WAITING_FOR_MANUAL_ANALYSIS" // Transação pendente de análise manual antes de seguir.
  | "SCHEDULED" // Transação foi agendada para processamento futuro.
  | "SCHEDULE_CANCELED" // Transação agendada foi cancelada antes da execução.
  | "PROCESSING" // Transação está sendo processada internamente.
  | "PENDING" // Transação foi enviada ao gateway de pagamento e aguarda processamento.
  | "REVERSAL_PENDING" // Reversão está pendente de execução.
  | "APPROVED" // Transação foi aprovada.
  | "FINISHED" // Transação concluída com sucesso.
  | "COMPLETED" // Transação finalizada com sucesso e os fundos foram transferidos.
  | "AUTHORIZED" // Transação foi autorizada, mas ainda não capturada.
  | "CAPTURED" // Transação foi capturada e os fundos foram debitados.
  | "REVERSAL_COMPLETED" // Reversão concluída com sucesso.
  | "ERROR" // Ocorreu um erro no processamento da transação.
  | "FAILED" // Transação falhou devido a erro técnico, saldo insuficiente ou outro motivo.
  | "REJECTED" // Transação foi rejeitada após análise.
  | "CANCELLED" // A transação foi cancelada antes da conclusão.
  | "DENIED" // Transação negada por alguma razão (exemplo: falta de autorização).
  | "REVERSED" // Transação revertida após ser completada, devido a erro ou contestação.
  | "REFUNDED" // O pagamento foi reembolsado ao cliente após ter sido completado.
  | "CHARGEBACK" // O pagamento foi estornado devido a contestação do cliente junto ao banco.
  | "EXPIRED" // A transação expirou sem ser concluída.
  | "ON_HOLD"; // A transação foi colocada em espera para revisão manual.

export type TransactionErrorStatus = Extract<
  TransactionStatus,
  "ERROR" | "FAILED" | "REJECTED"
>;

export type TransactionCategory = "P2P" | "TED" | "PIX" | "PAYMENT_SLIP";

export const transactionType = {
  cashIn: "CASH_IN",
  cashOut: "CASH_OUT",
} as const;

export type TransactionType =
  (typeof transactionType)[keyof typeof transactionType];
