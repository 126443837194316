import type {
  ContactResponse,
  ListContactsUseCase,
  ListContactsUseCaseErrorResponse,
  ListContactsUseCaseRequest,
  ListContactsUseCaseResponse,
} from "../../../domain/use-cases/contacts";
import type { HttpClient } from "../../http";
import { FavoredContactMapper } from "../../mappers";

export class ListContactsUseCaseImpl implements ListContactsUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(
    params?: ListContactsUseCaseRequest | undefined,
  ): Promise<ListContactsUseCaseResponse> {
    try {
      const response = await this.httpClient.request<ContactResponse>({
        method: "GET",
        url: this.url,
        params: {
          ...params,
          favoredContacts: String(params?.favoredContacts),
        },
      });
      if (response.statusCode >= 400) {
        return {
          success: false,
          error: response.body as unknown as ListContactsUseCaseErrorResponse,
        };
      }

      return {
        success: true,
        data: FavoredContactMapper.toDomain(response.body.Contacts),
      };
    } catch (error) {
      return {
        success: false,
        error: {
          code: "UNKNOWN_ERROR",
          errorMessages: [(error as Error).message || "An error occurred"],
        },
      };
    }
  }
}
