export const receiptKeys = {
  default: ["receiptDetail"],
  getReceiptDetail: (
    receiptId: string,
    receiptType: "p2p" | "TED" | "Pix" | "Payment",
    receiptOrigin: "transfers" | "statements",
  ) => [...receiptKeys.default, receiptId, receiptType, receiptOrigin],
  getReceiptDetailV2: ({ receiptId }: { receiptId: string }) => [
    ...receiptKeys.default,
    "V2",
    receiptId,
  ],
};
