import {
  TransactionCategory,
  TransactionStatus,
  transactionType,
  TransactionType,
} from "../../../domain";

export const getTransactionStatus = ({
  category,
  status,
  type,
}: {
  status: TransactionStatus;
  type: TransactionType;
  category: TransactionCategory;
}) => {
  const isCashin = type === transactionType.cashIn;

  const statusMap = {
    // Grupo 1: Status de Criação
    CREATED: {
      P2P: "Registrada",
      TED: "Registrada",
      PAYMENT_SLIP: "Registrado",
      PIX: "Registrada",
    },
    WAITING_FOR_APPROVAL: {
      P2P: "Aguardando Aprovação",
      TED: "Aguardando Aprovação",
      PAYMENT_SLIP: "Aguardando Aprovação",
      PIX: "Aguardando Aprovação",
    },
    WAITING_FOR_MANUAL_ANALYSIS: {
      P2P: "Aguardando Análise Manual",
      TED: "Aguardando Análise Manual",
      PAYMENT_SLIP: "Aguardando Análise Manual",
      PIX: "Aguardando Análise Manual",
    },
    SCHEDULED: {
      P2P: "Agendada",
      TED: "Agendada",
      PAYMENT_SLIP: "Agendado",
      PIX: "Agendada",
    },
    SCHEDULE_CANCELED: {
      P2P: "Agendamento Cancelado",
      TED: "Agendamento Cancelado",
      PAYMENT_SLIP: "Agendamento Cancelado",
      PIX: "Agendamento Cancelado",
    },

    // Grupo 2: Status de Processamento - pegar como status de pending no boleto
    PROCESSING: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Enviado",
      PIX: "Enviada",
    },
    PENDING: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Enviado",
      PIX: "Enviada",
    },
    REVERSAL_PENDING: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Enviado",
      PIX: "Enviada",
    },

    // Grupo 3: Status de Sucesso
    APPROVED: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Efetuado",
      PIX: "Enviada",
    },
    FINISHED: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Efetuado",
      PIX: "Enviada",
    },
    COMPLETED: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Efetuado",
      PIX: "Enviada",
    },
    AUTHORIZED: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Efetuado",
      PIX: "Enviada",
    },
    CAPTURED: {
      P2P: "Enviada",
      TED: "Enviada",
      PAYMENT_SLIP: "Efetuado",
      PIX: "Enviada",
    },
    REVERSAL_COMPLETED: {
      P2P: "Devolvida",
      TED: "Devolvida",
      PAYMENT_SLIP: "Devolvido",
      PIX: "Devolvida",
    },

    // Grupo 4: Status de Falha - COLOCAR TAXADO
    ERROR: {
      P2P: "Falhou",
      TED: "Falhou",
      PAYMENT_SLIP: "Falhou",
      PIX: "Falhou",
    },
    FAILED: {
      P2P: "Falhou",
      TED: "Falhou",
      PAYMENT_SLIP: "Falhou",
      PIX: "Falhou",
    },
    REJECTED: {
      P2P: "Falhou",
      TED: "Falhou",
      PAYMENT_SLIP: "Falhou",
      PIX: "Falhou",
    },

    // Grupo 5: Status de Cancelamento
    CANCELLED: {
      P2P: "Cancelada",
      TED: "Cancelada",
      PAYMENT_SLIP: "Cancelado",
      PIX: "Cancelada",
    },
    DENIED: {
      P2P: "Negada",
      TED: "Negada",
      PAYMENT_SLIP: "Negado",
      PIX: "Negada",
    },
    REVERSED: {
      P2P: "Estornada",
      TED: "Estornada",
      PAYMENT_SLIP: "Estornado",
      PIX: "Estornada",
    },
    REFUNDED: {
      P2P: "Devolvida",
      TED: "Devolvida",
      PAYMENT_SLIP: "Devolvido",
      PIX: "Devolvida",
    },
    CHARGEBACK: {
      P2P: "",
      TED: "",
      PAYMENT_SLIP: "",
      PIX: "",
    },

    // Grupo 6: Outros Status
    EXPIRED: {
      P2P: "Falhou",
      TED: "Falhou",
      PAYMENT_SLIP: "Falhou",
      PIX: "Falhou",
    },
    ON_HOLD: {
      P2P: "Processando",
      TED: "Processando",
      PAYMENT_SLIP: "Processando",
      PIX: "Processando",
    },
  };

  const cashinMap: Record<TransactionCategory, string> = {
    P2P: "Recebida",
    TED: "Recebida",
    PAYMENT_SLIP: "Recebido",
    PIX: "Recebida",
  };

  if (isCashin && !["REVERSED", "REVERSAL_COMPLETED"].includes(status))
    return cashinMap[category];

  return statusMap[status][category];
};
