import {
  AppOriginType,
  BankingOriginType,
  RequestMessage,
  ResponseMessage,
} from "./app-communication.types";

export class AppCommunicationManager {
  private isNative: boolean;
  private boundHandleMessage: (event: MessageEvent) => void;
  private messageResolvers: Map<BankingOriginType, (value: any) => void> =
    new Map();

  constructor() {
    this.isNative = Boolean(window.ReactNativeWebView);
    this.boundHandleMessage = this.handleMessage.bind(this);
    window.addEventListener("message", this.boundHandleMessage);
  }

  private handleMessage(event: MessageEvent): void {
    if (!this.isNative) return;

    try {
      const data = event.data;
      const originType = this.getCorrespondingBankingOrigin(data.origin);

      if (originType && this.messageResolvers.has(originType)) {
        const resolver = this.messageResolvers.get(originType);
        if (resolver) {
          resolver(data);
          this.messageResolvers.delete(originType);
        }
      }
    } catch (error) {
      console.error("Erro ao processar mensagem:", error);
    }
  }

  private getCorrespondingBankingOrigin(
    appOrigin: AppOriginType,
  ): BankingOriginType {
    return `banking-${appOrigin.replace("app-", "")}` as BankingOriginType;
  }

  public isNativeApp(): boolean {
    return this.isNative;
  }

  public async sendMessage<T extends BankingOriginType>(
    message: RequestMessage<T>,
  ): Promise<ResponseMessage<T>> {
    if (!this.isNative) {
      throw new Error("Não estamos em um ambiente nativo");
    }

    return new Promise((resolve, reject) => {
      try {
        this.messageResolvers.set(message.origin, resolve);
        window.ReactNativeWebView?.postMessage(JSON.stringify(message));
      } catch (error) {
        reject(error);
      }
    });
  }

  public destroy(): void {
    window.removeEventListener("message", this.boundHandleMessage);
    this.messageResolvers.clear();
  }
}
