import { IDENTITY_ENABLED } from "../../../config";
import { AppCommunicationManager } from "../app-communication/app-communication-service";
import { CafResponse } from "../app-communication/app-communication.types";
import { IdentitySDK } from "./identity-sdk";
import { IdentityService } from "./identity-service";

const errorMessagesMap: Record<string, string> = {
  "The user canceled the operation": "O usuário cancelou a operação.",
  "Invalid personId": "O Documento fornecido para validaçao nao e valido",
  "The personId provided in the body or path is not valid":
    "O documento fornecido para validação não é válido.",
  "User did not provide permission to collect location":
    "O usuário não forneceu permissão para coletar a localização.",
  "Operation to collect user location timeouted":
    "A operação para coletar a localização do usuário expirou.",
  "There was an error while trying to retrieve user location.":
    "Ocorreu um erro ao tentar recuperar a localização do usuário.",
  "Authentication method face_authentication is not supported by this version of the SDK":
    "O método de autenticação por face não é compatível com esta versão do SDK.",
  Unauthorized: "Sem autorização",
  "The authentication attempt is not pending":
    "A tentativa de autenticação não está pendente",
  "The identity does not have a phone number registered":
    "A identidade não tem um número de telefone cadastrado",
  "The identity does not have an email registered":
    "A identidade não tem um email cadastrado",
  "You must wait 30s before resending the code":
    "Você deve esperar 30s antes de reenviar o código",
  "The SDK is already running": "O SDK já está em execução",
  "Failed to make request": "Falha ao fazer a solicitação",
};

function handleCafError(error: Error): string {
  return errorMessagesMap[error.message] || error.message;
}

function handleAppMessage({ response, status }: CafResponse) {
  const isSuccessAuthorized = status === "success" && response.isAuthorized;

  const isPendingAuthorized = status === "pending" && response.isAuthorized;

  const successAttestation = status === "success" && response.attestation;

  const pendingAttestation = status === "pending" && response.attestation;

  const isAuthorized =
    (isSuccessAuthorized || isPendingAuthorized) &&
    !["cancelled", "error"].includes(status);

  const attestation = successAttestation || pendingAttestation;

  if (status === "error") {
    console.log(`error ${JSON.stringify(response)}`);
    throw new Error(response);
  }

  return {
    isAuthorized,
    attestation,
  };
}

type ValidateIdentityProps = {
  document: string;
};

type ValidateIdentityResponse = {
  isAuthorized: boolean;
  attestation?: string;
  message?: string;
};
export function cafIdentityService(
  policyId: string,
  identity: IdentitySDK | null = null,
  communicationManager: AppCommunicationManager,
): IdentityService {
  const validateIdentity = async (
    props: ValidateIdentityProps,
  ): Promise<ValidateIdentityResponse> => {
    if (IDENTITY_ENABLED === "false") {
      return {
        isAuthorized: true,
        message: "Identity is disabled",
      };
    }

    try {
      let isAuthorized = false;
      let attestation;

      if (communicationManager.isNativeApp()) {
        await communicationManager
          .sendMessage({
            origin: "banking-caf",
            document: props.document,
          })
          .then((cafResponse) => {
            const {
              attestation: appAttestation,
              isAuthorized: appIsAuthorized,
            } = handleAppMessage(cafResponse);

            isAuthorized = appIsAuthorized;
            attestation = appAttestation;
          });
      } else {
        const response = await identity?.verifyPolicy(props.document, policyId);

        isAuthorized = response?.isAuthorized ?? true;
        attestation = response?.attestation;
      }

      return {
        isAuthorized,
        attestation,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (identity?.isSdkError(error)) {
        return {
          isAuthorized: false,
          message: handleCafError(error),
        };
      }
      return { isAuthorized: false, message: handleCafError(error) };
    }
  };

  return {
    validateIdentity,
  };
}
