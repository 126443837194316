import { UserGuidingService } from "../../../infra/services/user-guiding/user-guiding-service";
import { useUserStore } from "../../../presentation/hooks/user-store";

let instance: UserGuidingService | null = null;

function makeUserGuidingServiceFactory(): void {
  const userId = useUserStore.getState().user?.userId;
  if (!userId) return;

  if (!instance) {
    instance = new UserGuidingService(userId);
    instance.init();
  }
}

useUserStore.subscribe((state) => {
  if (state.user?.userId) {
    makeUserGuidingServiceFactory();
  }
});

makeUserGuidingServiceFactory();
