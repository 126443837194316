import axios from "axios";
import { IP_SERVICE_URL } from "../../../config";

export class ClientInfoService {
  constructor() {}

  async getIpInfo() {
    try {
      const response = await axios.get<{
        ip: string;
        type: "IPv4" | "IPv6";
        continent: string;
        country: string;
        city: string;
        latitude: number;
        longitude: number;
        isp: string;
      }>(IP_SERVICE_URL);

      const result = {
        ipAddress: response.data.ip,
        latitude: response.data.latitude.toString(),
        longitude: response.data.longitude.toString(),
      };

      return result;
    } catch (error) {
      console.error("Error fetching client IP information:", error);
      return null;
    }
  }
}

