import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import { RequestIncomeTaxUseCase } from "../../../../domain/use-cases/tax-income/request-tax-income";
import { RequestIncomeTaxUseCaseImpl } from "../../../../infra/use-cases/income-tax/request-income-tax-usecase";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http";

export const makeRequestIncomeTaxUseCaseFactory =
  (): RequestIncomeTaxUseCase => {
    return new RequestIncomeTaxUseCaseImpl(
      makeAccountIdHttpClientDecoratorFactory(),
      makeApiUrlFactory({
        path: "api/Declarables",
        baseUrl: ACCOUNT_MANAGEMENT_API_URL,
      }),
    );
  };

export const requestIncomeTaxUseCase = makeRequestIncomeTaxUseCaseFactory();
