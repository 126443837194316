export class UserGuidingService {
  private userId: string;
  constructor(guidAccount: string) {
    this.userId = guidAccount;
  }

  public init() {
    if (!window.userGuiding) {
      console.error("User guiding unavailable");
      return;
    }
    window.userGuiding.identify(this.userId, {
      container: "Banking",
    });
  }
}
