import type { AccountStore } from "../../domain/store/account-store";
import { UserStore } from "../../domain/store/user-store";
import { SignOutUseCase } from "../../domain/use-cases";
import { CustomStorageImpl } from "../cache";
import type { AuthenticationService } from "../services/authentication/authentication-service";
import { AccountMenuChannel } from "../services/broadcast-channel/account-menu-service";

type StorageState = {
  accountId: string;
  name: string;
} | null;

export class SignOutUseCaseImpl implements SignOutUseCase {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly accountStore: AccountStore,
    private readonly userStore: UserStore,
    private readonly currentAccountStore: CustomStorageImpl<StorageState>,
    private history: History,
    private accountMenuChannel: AccountMenuChannel,
  ) {}

  async execute(): Promise<void> {
    await this.authenticationService.signOut({ global: true });
    this.accountStore.clear();
    this.userStore.deleteUser();
    this.currentAccountStore.setState(null);
    this.history.pushState({}, "", "/session/login");
    this.accountMenuChannel.destroy();
  }
}
