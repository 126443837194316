import { AppCommunicationManager } from "./app-communication-service";

export class CommunicationManagerSingleton {
  private static instance: AppCommunicationManager | null = null;

  public static getInstance(): AppCommunicationManager {
    if (!this.instance) {
      this.instance = new AppCommunicationManager();
    }
    return this.instance;
  }

  public static destroyInstance(): void {
    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
    }
  }
}

export const communicationManager = CommunicationManagerSingleton.getInstance();
