import type {
  CheckAppVersionUseCase,
  VersionStorageKey,
} from "../../../domain/use-cases/check-app-version-usecase";
import { VersionCheckerAdapter } from "../../../infra/services/versioning";
import { CheckAppVersionUseCaseImpl } from "../../../infra/use-cases/check-app-version-usecase";

export const makeCheckAppVersionUseCaseFactory = (
  storageKey: VersionStorageKey,
): CheckAppVersionUseCase => {
  const versionChecker = new VersionCheckerAdapter();
  return new CheckAppVersionUseCaseImpl(versionChecker, storageKey);
};

export const checkAppVersionUseCase = (storageKey: VersionStorageKey) =>
  makeCheckAppVersionUseCaseFactory(storageKey);
