import { z } from "zod";
import type { ErrorMapCtx, ZodIssueOptionalMessage } from "zod";

const envSchema = z.object({
  MANAGER_API_URL: z.string().url(),
  LOGIN_API_URL: z.string().url(),
  TED_API_URL: z.string().url(),
  PIX_API_URL: z.string().url(),
  DIGITAL_API_URL: z.string().url(),
  TOKEN_API_URL: z.string().url(),
  AUTH_COGNITO_USER_POOL_CLIENT_ID: z.string(),
  AUTH_COGNITO_USER_POOL_ID: z.string(),
  STATEMENTS_API_URL: z.string(),
  CONTACTS_API_URL: z.string(),
  P2P_API_URL: z.string(),
  AGENDA_API_URL: z.string(),
  SPOT_API_URL: z.string(),
  ACCESS_CONTROL_API_URL: z.string().url(),
  ACCOUNT_MANAGEMENT_API_URL: z.string().url(),
  FINANCE_PAYMENTS_API_URL: z.string().url(),
  PENDENCY_LIST_API_URL: z.string().url(),
  MANAGER_API_PASSWORD: z.string(),
  MANAGER_API_USERNAME: z.string(),
  IDENTITY_POLICY_ID: z.string(),
  IDENTITY_TOKEN: z.string(),
  IDENTITY_ENABLED: z.string().default("false"),
  IDENTITY_MOBILE_TOKEN: z.string(),
  IDENTITY_SDK_VERSION: z.string(),
  INTERNAL_STATEMENTS_API_TOKEN: z.string(),
  INTERNAL_STATEMENTS_API_URL: z.string().url(),
  SENTRY_DSN: z.string().url(),
  DASHBOARD_CDN: z.string().url(),
  RECEIVED_CDN: z.string().url(),
  SALES_CDN: z.string().url(),
  BALANCE_CDN: z.string().url(),
  CARDS_CDN: z.string().url(),
  CASH_OUT_CDN: z.string().url(),
  CHARGE_CDN: z.string().url(),
  NAV_BAR_CDN: z.string().url(),
  PIX_CDN: z.string().url(),
  RECEIPT_CDN: z.string().url(),
  STATEMENTS_CDN: z.string().url(),
  TRANSFERS_CDN: z.string().url(),
  IP_SERVICE_URL: z.string().url(),
});

const customErrorMap = (error: ZodIssueOptionalMessage, ctx: ErrorMapCtx) => {
  if (error.message === "Invalid URL.") {
    return { message: `Missing environment variable: ${error.path.join(".")}` };
  }
  return { message: ctx.defaultError };
};

let parsedEnv: z.infer<typeof envSchema> | undefined;
try {
  parsedEnv = envSchema.parse(process.env, {
    errorMap: customErrorMap,
  });
} catch (error) {
  if (error instanceof z.ZodError) {
    console.error("Environment validation error:", error.errors);
  } else {
    console.error("Unknown environment validation error:", error);
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ProcessEnv extends z.infer<typeof envSchema> {}
  }
}

export const env = parsedEnv as z.infer<typeof envSchema>;
