import { VersionCheckerImpl as LibVersionChecker } from "@hyperlocal/version-checker";

export interface VersionChecker {
  check(baseUrl: string): Promise<string>;
  onVersionChange(
    callback: (newVersion: string, oldVersion: string) => void,
  ): void;
}

export class VersionCheckerAdapter implements VersionChecker {
  private versionChecker: LibVersionChecker;

  constructor() {
    this.versionChecker = new LibVersionChecker();
  }

  async check(baseUrl: string): Promise<string> {
    return this.versionChecker.check(baseUrl);
  }

  onVersionChange(
    callback: (newVersion: string, oldVersion: string) => void,
  ): void {
    this.versionChecker.setOnVersionChange(callback);
  }
}
