import { queryOptions } from "@tanstack/react-query";
import { listIncomeTaxReports } from "../../../main/factories/use-cases/income-tax/list-reports";

export const incomeTaxFactory = {
  default: ["incomeTax"] as const,
  taxIncomeports: ({ accountId }: { accountId: string }) =>
    queryOptions({
      queryKey: [...incomeTaxFactory.default, accountId],
      queryFn: listIncomeTaxReports,
    }),
};
