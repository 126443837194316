import { queryOptions } from "@tanstack/react-query";
import { getClientIp } from "../../../main";

const THIRTY_MINUTES = 1000 * 60 * 30;

export const clientInfoFactory = {
  default: ["client-info"] as const,
  getIp: () => queryOptions({
    queryKey: [...clientInfoFactory.default, "ip"],
    queryFn: () => getClientIp(),
    staleTime: THIRTY_MINUTES
  })
}
