import { failure, success } from "../../../domain";
import {
  RequestIncomeTaxUseCase,
  RequestIncomeTaxUseCaseRequest,
  RequestIncomeTaxUseCaseResponse,
} from "../../../domain/use-cases/tax-income/request-tax-income";
import { HttpClient } from "../../http";

export class RequestIncomeTaxUseCaseImpl implements RequestIncomeTaxUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(
    data: RequestIncomeTaxUseCaseRequest,
  ): Promise<RequestIncomeTaxUseCaseResponse> {
    const response =
      await this.httpClient.request<RequestIncomeTaxUseCaseResponse>({
        method: "POST",
        url: this.url,
        body: data,
      });

    if (response.statusCode >= 200 && response.statusCode <= 299) {
      return success(undefined);
    }

    return failure(response.body) as RequestIncomeTaxUseCaseResponse;
  }
}
