import {
  ListTaxReportsResponse,
  ListTaxReportsUseCaseErrorResponse,
  ListTaxReportsUseCaseSuccessResponse,
  ListTaxReportUseCase,
} from "../../../domain/use-cases/tax-income/list-tax-reports";
import { HttpClient } from "../../http";

export class ListTaxReportsCaseImpl implements ListTaxReportUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
  ) {}

  async execute(): Promise<ListTaxReportsResponse> {
    try {
      const response =
        await this.httpClient.request<ListTaxReportsUseCaseSuccessResponse>({
          method: "GET",
          url: this.url,
        });

      if (response.statusCode >= 400) {
        return {
          success: false,
          error: response.body as unknown as ListTaxReportsUseCaseErrorResponse,
        };
      }

      return {
        success: true,
        data: response.body,
      };
    } catch (error) {
      return {
        success: false,
        error: {
          code: "UNKNOWN_ERROR",
          errorMessages: [
            (error as Error).message || "An unknown error occurred",
          ],
        },
      };
    }
  }
}
