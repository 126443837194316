import { useAccountStore, useUserStore } from "../presentation";

export const useNewStatementPermission = () => {
  const { currentAccountId } = useAccountStore();
  const user = useUserStore((state) => state.getUser());

  const selectedAccount = user?.accounts.find(
    (account) => account.accountId === currentAccountId,
  );

  return selectedAccount?.useNewBankStatement ?? false;
};
