import { ACCOUNT_MANAGEMENT_API_URL } from "../../../../config";
import { ListTaxReportUseCase } from "../../../../domain/use-cases/tax-income/list-tax-reports";
import { ListTaxReportsCaseImpl } from "../../../../infra/use-cases/income-tax";
import { makeAccountIdHttpClientDecoratorFactory } from "../../decorators/make-account-id-http-client-decorator-factory";
import { makeApiUrlFactory } from "../../http";

const makeTaxReportListUseCaseFactory = (): ListTaxReportUseCase => {
  return new ListTaxReportsCaseImpl(
    makeAccountIdHttpClientDecoratorFactory(),
    makeApiUrlFactory({
      path: "api/Declarables",
      baseUrl: ACCOUNT_MANAGEMENT_API_URL,
    }),
  );
};

export const listIncomeTaxReports = async () => {
  const incomeTaxReportList = makeTaxReportListUseCaseFactory();

  const response = await incomeTaxReportList.execute();

  if (!response.success && "error" in response) {
    throw new Error(response.error.errorMessages.join(", "));
  }

  return response.data;
};
