import type { NativeEventSender, SendMessageFn } from "./native-event-sender";

export class NativeEventSenderImpl<T extends Record<string, unknown>>
  implements NativeEventSender<T>
{
  private isNative: boolean;

  constructor() {
    this.isNative =
      typeof window !== "undefined" && !!window.ReactNativeWebView;
  }

  public sendMessage: SendMessageFn<T> = (type, payload) => {
    if (this.isNative) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ type, data: payload }),
      );
    }
  };

  public getIsNative(): boolean {
    return this.isNative;
  }
}
